import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import JsonUtil from "./JsonUtil";
import Help from "./Help";

const MyRoutes = () => (
  <Router>
    <Routes>
      {/*<Route exact path="/" element={<App/>} />*/}
      {/*<Route path="/util" element={<JsonUtil/>} />*/}
        <Route path={"/"} element={<Help/>} />
    </Routes>
  </Router>
);


export default MyRoutes;