import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {ButtonGroup} from "@mui/material";

export default function Navbar() {
    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static">
                <Toolbar>
                    {/*<IconButton*/}
                    {/*  size="large"*/}
                    {/*  edge="start"*/}
                    {/*  color="inherit"*/}
                    {/*  aria-label="menu"*/}
                    {/*  sx={{ mr: 2 }}*/}
                    {/*>*/}
                    {/*  <MenuIcon />*/}
                    {/*</IconButton>*/}
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        Pwdl - Local WEBUI
                    </Typography>
                    <ButtonGroup variant="outlined" aria-label="Basic button group">
                        <Button href={'/'}>Help</Button>
                        {/*<Button href={'/util'}>Utility</Button>*/}
                        {/*<Button href={'/help'}>Help</Button>*/}
                    </ButtonGroup>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

