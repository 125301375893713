import {Box, Container, Typography} from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SubHelp from "./SubHelp";
import DeleteIcon from '@mui/icons-material/Delete';
import Carousel from "react-material-ui-carousel";

const Help = () => {

    const tamper_script = [
        {
            label: 'Tampermonkey Extension',
            videoPath: 'https://github.com/shubhamakshit/pwdlv3_assets/raw/main/Videos/tamper.mp4',
        },
        {
            label: 'User Script Installation',
            videoPath: 'https://github.com/shubhamakshit/pwdlv3_assets/raw/main/Videos/script.mp4',
        }
    ];
    const pwdl_install = [
        {
            label: 'PWDL_INSTALLATION',
            videoPath: 'https://github.com/shubhamakshit/pwdlv3_assets/raw/main/Videos/pwdl.mp4',
        },
        {
            label: 'Token Installtion',
            videoPath: 'https://github.com/shubhamakshit/pwdlv3_assets/raw/main/Videos/token.mp4',
        }
    ];

    const webui = [
        {
            label: 'WebUI',
            videoPath: "https://github.com/shubhamakshit/pwdlv3_assets/raw/main/Videos/web.mp4",
        },
        {
            label: "Download",
            videoPath: "https://github.com/shubhamakshit/pwdlv3_assets/raw/main/Videos/dl.mp4"
        }
    ]

    return (

        <div>
            <Box sx={{height: '100vh',display:"flex",alignItems:"center"}}>
                <Typography variant="h1" component="div" sx={{flexGrow: 1}}>
                    <Container>
                        <h1>Stuck?</h1>
                        <p>Follow the steps below to get started!</p>
                    </Container>
                </Typography>
            </Box>
            <SubHelp
                heading={"Installation of Tampermonkey Script"}
                content={"Install Tampermonkey from the Chrome Web Store. Once installed, click on the Tampermonkey icon in the top right of your browser. Click on \"Create a new script...\" and paste the contents of the script into the editor. Click \"File\" and \"Save\" to save the script. The script should now be active."}
                // childrenAfterTypography={}
                videoLinks={tamper_script}
            >
                <Container style={{
                    display: 'flex',
                    gap: "8px"
                }}>
                    <Button variant="outlined" endIcon={<OpenInNewIcon/>} href={"https://chromewebstore.google.com/detail/tampermonkey/dhdgffkkebhmkfjojejmpbldmpobfkfo?hl=en"} target={"_blank"}>
                        CHROME
                        EXTENSION
                    </Button>
                    <Button variant="outlined" endIcon={<OpenInNewIcon/>} href={"https://gist.github.com/shubhamakshit/0a678e8f565c57c7d2fe6dfbae93657f"} target={"_blank"} >
                        USERSCRIPT
                    </Button>
                </Container>
            </SubHelp>
            <SubHelp
                heading={"Installation of PWDL"}
                content={"Download the PWDL executable from the releases page. Run the executable and follow the on-screen instructions. Once installed, open the application and follow the on-screen instructions to install the token."}
                videoLinks={pwdl_install}
                >
                <Container style={{
                    display: 'flex',
                    gap: "8px"
                }}>
                    <Button variant="outlined" endIcon={<OpenInNewIcon/>} href={"https://github.com/shubhamakshit/pwdlv3/releases/tag/windows"} target={"_blank"}>
                        Download PWDL
                    </Button>
                    <Button variant="outlined" endIcon={<OpenInNewIcon/>} href={"https://pw.live"} target={"_blank"}>
                        pw.live
                    </Button>
                </Container>
            </SubHelp>
            <SubHelp
                heading={"Start the local WebUI"}
                content={"Run the follwing command in the terminal to start the local WebUI. The WebUI will be available at http://localhost:5000"}
                videoLinks={webui}>
                <Container style={{
                    display: 'flex',
                    gap: "8px"
                }}>
                    <pre>
                        pwdl --web
                    </pre>
                </Container>
            </SubHelp>


            {/*<Container sx={{height: '100vh'}}>*/}
            {/*    <Accordion>*/}

            {/*        <AccordionSummary*/}
            {/*            expandIcon={<ArrowDropDownIcon/>}*/}
            {/*            aria-controls="panel2-content"*/}
            {/*            id="panel2-header"*/}
            {/*        >*/}
            {/*            <Typography>Accordion 2</Typography>*/}
            {/*        </AccordionSummary>*/}
            {/*        <AccordionDetails>*/}
            {/*            <Box>*/}
            {/*                <Typography>*/}
            {/*                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse*/}
            {/*                    malesuada lacus ex, sit amet blandit leo lobortis eget.*/}
            {/*                </Typography>*/}
            {/*            </Box>*/}
            {/*        </AccordionDetails>*/}
            {/*    </Accordion>*/}
            {/*</Container>*/}

        </div>

    );


};

export default Help;