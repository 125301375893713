import React from 'react';
import { Container, Accordion, AccordionSummary, AccordionDetails, Typography, Box, Button } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Carousel from 'react-material-ui-carousel';

const SubHelp = ({ heading,content, videoLinks,children }) => {
  return (
    <Container sx={{ height: '100vh' }}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>{heading}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ m: 2 }}>
            <Typography>{content}</Typography>
            <br />
            {children}
            <Carousel autoPlay={false} animation={"slide"} navButtonsAlwaysVisible={true} swipe={false} >
              {videoLinks.map((item, index) => (
                <Container key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%'
                  }}>
                  <video key={index} controls
                    style={{
                      width: '100%',
                      height: 'auto'
                    }}>
                    <source src={item.videoPath} type="video/mp4" />
                  </video>
                </Container>
              ))}
            </Carousel>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default SubHelp;
